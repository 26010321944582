import { AxiosResponse } from 'axios';
import { InvoiceTriangulationProps } from '../../components/Triangulation/InvoiceTriangulation';
import Request from './request';
import InvoiceAttachment from '../../shared/interfaces/invoiceAttachment.interface';
import FindResponse from '../../shared/interfaces/findResponse.interface';

interface SaveAttachmentDTO {
    type: 'FINANCING' | 'OTHERS' | 'DEPOSIT';
    file: string | ArrayBuffer;
    fileExtension: string;
    voucherNumber: string | null;
    voucherDate: string | null;
    voucherAmount: number | null;
    bankAccount?: number;
}

interface ValidateInvoiceDTO {
    discount: number;
    deliveryDate: string;
    isFutureSale: boolean;
    isTriangulation: boolean;
    feedback: string;
}

export default class InvoiceHttpService {
    private static uri = 'invoices';

    public static index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static indexBudget(params: {}) {
        return Request.get(`${this.uri}/listBudget`, params);
    }

    public static getPaidInvoiceBySeller(sellerId: any) {
        return Request.get(`${this.uri}/seller/${sellerId.id}`);
    }

    public static logs(id: number) {
        return Request.get(`${this.uri}/${id}/logs`);
    }

    public static contributorLogs(id: number) {
        return Request.get(`${this.uri}/${id}/contributorLogs`);
    }

    public static show(id: string) {
        return Request.get(`${this.uri}/${id}`);
    }

    public static async destroy(id: number) {
        return Request.del(`${this.uri}/${id}`);
    }

    public static showByUserIdAndStatus(params: any) {
        return Request.get(`${this.uri}/${params.status}`, {});
    }

    public static get(data: any) {
        return Request.get(this.uri, data);
    }

    public static addItem(item: any) {
        return Request.post(`${this.uri}/item`, item);
    }

    public static getAttachments(
        invoiceId: string,
        params?: { take: number; skip: number },
    ): Promise<AxiosResponse<FindResponse<InvoiceAttachment>>> {
        return Request.get(`${this.uri}/${invoiceId}/attachments`, params);
    }

    public static getAttachment(
        invoiceId: string,
        attachmentId: string,
    ): Promise<AxiosResponse<InvoiceAttachment>> {
        return Request.get(
            `${this.uri}/${invoiceId}/attachments/${attachmentId}`,
        );
    }

    public static addAttachment(
        invoiceId: string,
        attachment: SaveAttachmentDTO,
    ): Promise<AxiosResponse<InvoiceAttachment>> {
        return Request.post(`${this.uri}/${invoiceId}/attachments`, attachment);
    }

    public static updateAttachment(
        invoiceId: string,
        attachmentId: string,
        attachment: Omit<SaveAttachmentDTO, 'file' | 'fileExtension'>,
    ): Promise<AxiosResponse<InvoiceAttachment>> {
        return Request.patch(
            `${this.uri}/${invoiceId}/attachments/${attachmentId}`,
            attachment,
        );
    }

    public static async removeAttachment(
        invoiceId: string,
        attachmentId: number,
    ) {
        return Request.del(
            `${this.uri}/${invoiceId}/attachments/${attachmentId}`,
        );
    }

    public static async updateAttachmentStatus(
        invoiceId: number,
        attachmentId: number,
    ): Promise<AxiosResponse<InvoiceAttachment>> {
        return Request.patch(
            `${this.uri}/${invoiceId}/attachments/${attachmentId}/status`,
        );
    }

    public static addFinancing(item: any) {
        return Request.post(`${this.uri}/financing`, item);
    }

    public static addByKit(item: any) {
        return Request.post(`${this.uri}/kit`, item);
    }

    public static update(item: any) {
        return Request.patch(`${this.uri}`, item);
    }

    public static emptyCart() {
        return Request.patch(`${this.uri}/empty-cart`);
    }

    public static changeCartType(type: string) {
        return Request.patch(`${this.uri}/change-cart-type/${type}`);
    }

    public static updateInvoiceCustomer(sellerId: string, status: string) {
        return Request.patch(
            `${this.uri}/update-customer/${sellerId}/${status}`,
        );
    }

    public static setStatus(id: string, data: {}) {
        return Request.post(`${this.uri}/${id}/set-status`, data);
    }

    public static updateInsurance(id: string) {
        return Request.patch(`${this.uri}/${id}/insurance`);
    }

    public static canValidate(id: number) {
        return Request.get(`${this.uri}/${id}/can-validate`);
    }

    public static validate(id: number, data: ValidateInvoiceDTO) {
        return Request.patch(`${this.uri}/${id}/validate`, data);
    }

    public static invalidate(id: string, data: { feedback: string }) {
        return Request.patch(`${this.uri}/${id}/invalidate`, data);
    }

    public static approve(id: number) {
        return Request.patch(`${this.uri}/${id}/approve`);
    }

    public static reprove(id: string, data: { observation: string }) {
        return Request.patch(`${this.uri}/${id}/reprove`, data);
    }

    public static useSameAddressForDelivery(id: string) {
        return Request.post(`${this.uri}/${id}/use-same-address-for-delivery`);
    }

    public static confirmPayment(id: string, data: {}) {
        return Request.patch(`${this.uri}/${id}/confirm-payment`, data);
    }

    public static async cancel(id: number, reason: any = {}) {
        return Request.patch(`${this.uri}/${id}/cancel`, reason);
    }

    public static async remake(id: number, keepAttachments: boolean = false) {
        return Request.get(`${this.uri}/${id}/remake`, {
            keepAttachments,
        });
    }

    public static print(id: number) {
        return Request.get(`${this.uri}/${id}/print`);
    }

    public static getNfeSimpleShipment(id: number) {
        return Request.get(`${this.uri}/${id}/nfe-simple-shipment`);
    }

    public static getNfeFutureSale(id: number) {
        return Request.get(`${this.uri}/${id}/nfe-future-sale`);
    }

    public static budgetItems(id: number, params: any[]) {
        return Request.patch(`${this.uri}/budget/${id}/items`, params);
    }

    public static recalculate(id: string) {
        return Request.patch(`${this.uri}/${id}/recalculate`);
    }

    public static getInvoicesPaycredit(params: {}) {
        return Request.get(`${this.uri}/listInvoicePaycredit`, params);
    }

    public static checkCreditCard(invoicePaycredit: any) {
        return Request.post(`${this.uri}/checkCreditCard`, invoicePaycredit);
    }

    public static sendCreditCardAnalysis(body: {}) {
        return Request.post(`${this.uri}/sendCreditCardAnalysis`, body);
    }

    public static getInvoicesPaycreditLogs(params: {}) {
        return Request.get(`${this.uri}/listInvoicePaycreditLogs`, params);
    }

    public static selectOthersPaymentMethod(id: number, data: {}) {
        return Request.post(`${this.uri}/${id}/selectPaymentMethod`, data);
    }

    public static showTriangulation(id: string) {
        return Request.get(`${this.uri}/${id}/triangulation`);
    }

    public static createTriangulation(
        id: string,
        invoiceTriangulation: InvoiceTriangulationProps,
    ) {
        return Request.post(
            `${this.uri}/${id}/triangulation`,
            invoiceTriangulation,
        );
    }

    public static updateTriangulation(
        id: string,
        invoiceTriangulation: InvoiceTriangulationProps,
    ) {
        return Request.patch(
            `${this.uri}/${id}/triangulation`,
            invoiceTriangulation,
        );
    }

    public static approveTriangulation(id: string) {
        return Request.patch(`${this.uri}/${id}/triangulation/approve`);
    }

    public static getNfeTriangulation(id: number) {
        return Request.get(`${this.uri}/${id}/triangulation/nf`);
    }

    public static updateDeliveryDate(id: number) {
        return Request.patch(`${this.uri}/${id}/delivery-date`);
    }

    public static updateCommission(id: number) {
        return Request.patch(`${this.uri}/${id}/commission`);
    }

    public static sendToFretefy(id: number) {
        return Request.post(`${this.uri}/${id}/fretefy-queue`);
    }
}
