import { Dropdown, Button } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ReactCancelIcon } from '../../../assets/icons/cancel.svg';
import { ReactComponent as ReactFeedbackIcon } from '../../../assets/icons/feedback.svg';
import { ReactComponent as ReactListIcon } from '../../../assets/icons/list.svg';
import { ReactComponent as ReactMoreIcon } from '../../../assets/icons/more.svg';
import { ReactComponent as ReactPaymentIcon } from '../../../assets/icons/payment.svg';
import { ReactComponent as ReactPrintIcon } from '../../../assets/icons/print.svg';
import { ReactComponent as ReactTriangulationIcon } from '../../../assets/icons/triangulation.svg';
import { ReactComponent as ReactAttachmentIcon } from '../../../assets/icons/attachment.svg';
import { ReactComponent as ReactTruckIcon } from '../../../assets/icons/truck.svg';
import { ReactComponent as ReactUndoIcon } from '../../../assets/icons/undo.svg';
import { ReactComponent as ReactFretefyIcon } from '../../../assets/icons/fretefy.svg';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import {
    invoiceDetailRoute,
    invoiceAttachmentsListRoute,
    invoiceTriangulationRoute,
} from '../../../routes/config';
import { Role } from '../../../constants/role';
import { useAuth } from '../../../contexts/authContext';
import useInvoices from '../../../hooks/useInvoices';
import userHasRoles from '../../../utils/userHasRoles';
import { PaymentMethod } from '../../../constants/paymentMethod';
import InvoiceHttpService from '../../../services/http/invoice-http';
import arrayBufferToBase64 from '../../../utils/arrayBufferToBase64';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import StyledDropdownItem from './styles';

interface InvoiceTableRowDropdownProps {
    invoice: any;
}

const attachmentsDisabledStatus = [
    InvoiceStatus.Cart,
    InvoiceStatus.Budget,
    InvoiceStatus.BudgetExpired,
    InvoiceStatus.Invoice,
    InvoiceStatus.InvoiceValidated,
    InvoiceStatus.InvoiceReproved,
    InvoiceStatus.Processing,
];

const cancelInvoiceStatus = [
    InvoiceStatus.InvoiceApproved,
    InvoiceStatus.InvoicePayedErp,
    InvoiceStatus.InvoiceBillingErp,
    InvoiceStatus.InvoiceBilled,
    InvoiceStatus.InvoiceDispatched,
    InvoiceStatus.InvoiceDeliveryEnRoute,
    InvoiceStatus.InvoiceDeliveryInTransit,
    InvoiceStatus.InvoiceDelivered,
    InvoiceStatus.InvoiceAnalysis,
];

const triangulationStatus = [
    InvoiceStatus.InvoiceBilled,
    InvoiceStatus.InvoiceDispatched,
    InvoiceStatus.InvoiceDeliveryEnRoute,
    InvoiceStatus.InvoiceDeliveryInTransit,
    InvoiceStatus.InvoiceSeparate,
    InvoiceStatus.InvoiceDelivered,
    InvoiceStatus.WaitingApproval,
];

export default function InvoiceTableRowDropdown({
    invoice,
}: InvoiceTableRowDropdownProps) {
    const { user } = useAuth();

    const history = useHistory();

    const {
        setSelectedInvoice,
        setShowReturnConfirmation,
        setShowReproveValidatedConfirmation,
        setShowFeedbackInvoiceModal,
        setShowRemakeConfirmation,
        setShowReleasePaymentMethodInvoiceModal,
        setShowReproveConfirmation,
        setShowInvoiceCancelmentModal,
        setShowInvoiceTriangulationNfDisplayModal,
        setShowInvoiceSimpleShipmentNfDisplayModal,
        setShowSendFretefyConfirmation,
    } = useInvoices();

    const isIntegrator = userHasRoles(user, [
        Role.Integrator,
        Role.Contributor,
        Role.Manager,
    ]);

    const isAdministratorOrFinancial = userHasRoles(user, [
        Role.Administrator,
        Role.Financial,
    ]);

    const isAdminOrComSupOrCom = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.Commercial,
    ]);

    const isCommercialSupervisor = userHasRoles(user, [
        Role.CommercialSupervisor,
    ]);

    const isCommercial = userHasRoles(user, [Role.Commercial]);

    const isCustomerSuccess = userHasRoles(user, [Role.CustomerSuccess]);

    const isFinancial = userHasRoles(user, [Role.Financial]);

    const canAccessPreReturn = isAdminOrComSupOrCom || isFinancial;

    const preReturnAvailableStatuses = [
        InvoiceStatus.InvoiceBillingErp,
        InvoiceStatus.InvoicePayedErp,
        InvoiceStatus.InvoiceBilled,
        InvoiceStatus.InvoiceDispatched,
        InvoiceStatus.InvoiceDeliveryInTransit,
        InvoiceStatus.InvoiceDeliveryEnRoute,
        InvoiceStatus.InvoiceDelivered,
    ];

    const preReturnIsAvailable =
        invoice.nf && preReturnAvailableStatuses.includes(invoice.status);

    const canAccessTriangulation =
        (isIntegrator || isAdminOrComSupOrCom) &&
        triangulationStatus.includes(invoice.status);

    const canAccessReleasePayment =
        isAdministratorOrFinancial &&
        invoice.paymentMethod === PaymentMethod.Others &&
        !invoice.othersPaymentMethodCode &&
        invoice.status === InvoiceStatus.Invoice;

    const canCancelInvoiceAsAdministratorOrFinancial =
        isAdministratorOrFinancial &&
        cancelInvoiceStatus.includes(invoice.status);

    const canCancelInvoiceAsCommercialSupervisor =
        isCommercialSupervisor &&
        invoice.status === InvoiceStatus.InvoiceApproved &&
        [PaymentMethod.Financing, PaymentMethod.Others].includes(
            invoice.paymentMethod,
        );

    const canCancelInvoiceAsCommercial =
        isCommercial &&
        invoice.status === InvoiceStatus.InvoiceApproved &&
        invoice.paymentMethod === PaymentMethod.Financing;

    const canCancelInvoice =
        canCancelInvoiceAsAdministratorOrFinancial ||
        canCancelInvoiceAsCommercialSupervisor ||
        canCancelInvoiceAsCommercial;

    const triangulationNfeIsAvailable =
        invoice.isTriangulation && triangulationStatus.includes(invoice.status);

    const canAccessNfeFutureSale =
        invoice.nfeFutureSale &&
        !(invoice.status === InvoiceStatus.InvoiceCanceled && isIntegrator);

    const canAccessNfeSimpleShipment =
        invoice.nfe &&
        !(invoice.status === InvoiceStatus.InvoiceCanceled && isIntegrator);

    const isAdminsitratorAndLogistics = userHasRoles(user, [
        Role.Administrator,
        Role.Logistics,
    ]);

    const canAcessSendFretefy =
        isAdminsitratorAndLogistics &&
        invoice.status === InvoiceStatus.InvoicePayedErp &&
        !invoice.fretefyId;

    const printInvoiceMutation = useMutation({
        mutationFn: async () => InvoiceHttpService.print(invoice.id),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao gerar o arquivo');
        },
        onSuccess: (data, id) => {
            const a = document.createElement('a');

            a.href = `data:application/pdf;base64,${arrayBufferToBase64(
                data?.data?.data,
            )}`;
            a.download = `Pedido #${id}.pdf`;
            a.click();
        },
    });

    const printFutureSaleNfeMutation = useMutation({
        mutationFn: async () => InvoiceHttpService.getNfeFutureSale(invoice.id),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                return;
            }

            toast.error('Ocorreu um erro ao gerar o arquivo.');
        },
        onSuccess: (response) => {
            const a = document.createElement('a');

            a.href = `data:application/pdf;base64,${response?.data?.data}`;
            a.download = 'NFe_Venda_Futura.pdf';
            a.click();
        },
    });

    return (
        <Dropdown key="left">
            <Dropdown.Toggle
                bsPrefix="nexen"
                as={Button}
                variant="text"
                style={{
                    padding: '6px',
                    marginRight: '8px',
                }}
            >
                <ReactMoreIcon fill="#a3a3a3" width="10" height="20" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {isIntegrator && invoice.approvalFeedback && (
                    <>
                        <StyledDropdownItem
                            onClick={() => {
                                setSelectedInvoice(invoice);
                                setShowFeedbackInvoiceModal(true);
                            }}
                        >
                            <ReactFeedbackIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            Feedback
                        </StyledDropdownItem>

                        <Dropdown.Divider />
                    </>
                )}
                {invoice.type !== 'BF' && (
                    <>
                        <StyledDropdownItem
                            onClick={() => {
                                setSelectedInvoice(invoice);
                                setShowRemakeConfirmation(true);
                            }}
                        >
                            <ReactUndoIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            Refazer orçamento
                        </StyledDropdownItem>

                        <Dropdown.Divider />
                    </>
                )}
                <StyledDropdownItem
                    onClick={() =>
                        history.push(invoiceDetailRoute.build(invoice))
                    }
                >
                    <ReactListIcon
                        fill="#707070"
                        width="18"
                        height="18"
                        className="mr-2"
                    />
                    Detalhes do pedido
                </StyledDropdownItem>
                <Dropdown.Divider />
                {isAdministratorOrFinancial &&
                    [PaymentMethod.Financing, PaymentMethod.Others].includes(
                        invoice.paymentMethod,
                    ) && (
                        <>
                            <StyledDropdownItem
                                onClick={() =>
                                    history.push(
                                        invoiceAttachmentsListRoute.build(
                                            invoice,
                                        ),
                                    )
                                }
                                disabled={attachmentsDisabledStatus.includes(
                                    invoice.status,
                                )}
                            >
                                <ReactAttachmentIcon
                                    stroke={
                                        attachmentsDisabledStatus.includes(
                                            invoice.status,
                                        )
                                            ? '#adb5bd'
                                            : '#707070'
                                    }
                                    width="18"
                                    height="18"
                                    className="mr-2"
                                />
                                Comprovantes
                            </StyledDropdownItem>
                            <Dropdown.Divider />
                        </>
                    )}
                {invoice.isTriangulation && (
                    <>
                        <StyledDropdownItem
                            onClick={() =>
                                history.push(
                                    invoiceTriangulationRoute.build(invoice),
                                )
                            }
                            disabled={!canAccessTriangulation}
                        >
                            <ReactTriangulationIcon
                                stroke={
                                    !canAccessTriangulation
                                        ? '#adb5bd'
                                        : '#707070'
                                }
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            Conta e Ordem
                        </StyledDropdownItem>
                        <Dropdown.Divider />
                    </>
                )}
                {canAccessReleasePayment && (
                    <>
                        <StyledDropdownItem
                            onClick={() => {
                                setSelectedInvoice(invoice);
                                setShowReleasePaymentMethodInvoiceModal(true);
                            }}
                        >
                            <ReactPaymentIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            Liberar forma de pagamento
                        </StyledDropdownItem>
                        <Dropdown.Divider />
                    </>
                )}
                <StyledDropdownItem
                    onClick={() => printInvoiceMutation.mutateAsync(invoice.id)}
                >
                    <ReactPrintIcon
                        fill="#707070"
                        width="18"
                        height="18"
                        className="mr-2"
                    />
                    Baixar pedido
                </StyledDropdownItem>

                {canAccessNfeSimpleShipment && (
                    <>
                        <Dropdown.Divider />
                        <StyledDropdownItem
                            onClick={() => {
                                setSelectedInvoice(invoice);
                                setShowInvoiceSimpleShipmentNfDisplayModal(
                                    true,
                                );
                            }}
                        >
                            <ReactTruckIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            NFE
                        </StyledDropdownItem>
                    </>
                )}
                {canAccessNfeFutureSale && (
                    <>
                        <Dropdown.Divider />
                        <StyledDropdownItem
                            onClick={() =>
                                printFutureSaleNfeMutation.mutateAsync()
                            }
                        >
                            <ReactTruckIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            NFE venda futura
                        </StyledDropdownItem>
                    </>
                )}
                {triangulationNfeIsAvailable && (
                    <>
                        <Dropdown.Divider />
                        <StyledDropdownItem
                            onClick={() => {
                                setSelectedInvoice(invoice);
                                setShowInvoiceTriangulationNfDisplayModal(true);
                            }}
                        >
                            <ReactTruckIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            NFE Conta e Ordem
                        </StyledDropdownItem>
                    </>
                )}
                {canCancelInvoice && (
                    <>
                        <Dropdown.Divider />
                        <StyledDropdownItem
                            onClick={() => {
                                setSelectedInvoice(invoice);
                                setShowInvoiceCancelmentModal(true);
                            }}
                        >
                            <ReactCancelIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            Cancelar pedido ERP
                        </StyledDropdownItem>
                    </>
                )}
                {invoice.status === InvoiceStatus.Invoice &&
                    (isCustomerSuccess || isAdminOrComSupOrCom) && (
                        <>
                            <Dropdown.Divider />
                            <StyledDropdownItem
                                onClick={() => {
                                    setSelectedInvoice(invoice);
                                    setShowReproveConfirmation(true);
                                }}
                            >
                                <ReactCancelIcon
                                    fill="#707070"
                                    width="18"
                                    height="18"
                                    className="mr-2"
                                />
                                Reprovar pedido
                            </StyledDropdownItem>
                        </>
                    )}
                {invoice.status === InvoiceStatus.InvoiceValidated &&
                    isAdminOrComSupOrCom && (
                        <>
                            <Dropdown.Divider />
                            <StyledDropdownItem
                                onClick={() => {
                                    setSelectedInvoice(invoice);
                                    setShowReproveValidatedConfirmation(true);
                                }}
                            >
                                <ReactCancelIcon
                                    fill="#707070"
                                    width="18"
                                    height="18"
                                    className="mr-2"
                                />
                                Reprovar pedido
                            </StyledDropdownItem>
                        </>
                    )}
                {canAccessPreReturn && preReturnIsAvailable && (
                    <>
                        <Dropdown.Divider />
                        <StyledDropdownItem
                            onClick={() => {
                                setSelectedInvoice(invoice);
                                setShowReturnConfirmation(true);
                            }}
                        >
                            <ReactTruckIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            Solicitar Pré-Devolução
                        </StyledDropdownItem>
                    </>
                )}
                {canAcessSendFretefy && (
                    <>
                        <Dropdown.Divider />
                        <StyledDropdownItem
                            onClick={() => {
                                setSelectedInvoice(invoice);
                                setShowSendFretefyConfirmation(true);
                            }}
                        >
                            <ReactFretefyIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="mr-2"
                            />
                            Enviar para o Fretefy
                        </StyledDropdownItem>
                    </>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}
